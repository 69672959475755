const emailUpsellConstants = {
  // orgins
  AgeVerification: 'ageVerification',
  // keys
  Enter: 'Enter',
  // error codes
  InvalidEmailCode: 9,
  InvalidPasswordCode: 8,
  // translation string keys
  ActionChangeEmail: 'Action.ChangeEmail',
  ActionSent: 'Action.Sent',
  ActionContinue: 'Action.Continue',
  ActionGenericSkip: 'Action.GenericSkip',
  DescriptionAddEmailTextOver13: 'Description.IDVerificationAddEmailText',
  DescriptionAddEmailTextUnder13: 'Description.IDVerificationAddEmailText',
  HeadingAddEmail: 'Heading.AddEmail',
  LabelEmailInputPlaceholderOver13: 'Label.EmailInputPlaceholderOver13',
  LabelEmailInputPlaceholderUnder13: 'Label.EmailInputPlaceholderUnder13',
  MessageInvalidEmailAddress: 'Message.InvalidEmailAddress'
};

export { emailUpsellConstants as default };
