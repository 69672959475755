export const rootElementId = 'id-verification-container';

export const emailUpsellTranslationConfig = {
  common: [],
  feature: 'Feature.VerificationUpsell'
};

// translations for id verification view
export const verificationTranslationConfig = {
  common: ['CommonUI.Controls'],
  feature: 'Verification.Identity'
};
